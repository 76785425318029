import React, { FC } from 'react';
import { IArticleTeaser } from 'gatsby-theme-husky/src/components/ArticleTeaser/models';
import { Link } from 'gatsby';
import Button from 'gatsby-theme-husky/src/common/Button';
import TagsList from 'gatsby-theme-husky/src/components/TagsList';
import { customStructurePickerUtils, stringUtils } from 'gatsby-theme-husky/src/utils';
import ARTICLES from 'gatsby-theme-husky/src/shared/static/content';
import ArticleTeaserContentTitle from './ArticleTeaserContentTitle';

const ArticleTeaserContent: FC<IArticleTeaser> = ({
  articleTeaser: {
    url,
    properties: { articleBody, title, tags },
  },
  page: {
    properties: { articleTeaserCtaButtonAriaLabel, articleTeaserCTAButtonText },
  },
}) => {
  const articleIntroductionText = customStructurePickerUtils.getStructurePropertiesDataWOHtml(
    articleBody,
    ARTICLES.INTRODUCTION,
    ARTICLES.INTRO_TEXT
  );

  const teaserCapitalized = stringUtils.titleCapitalized(title);

  return (
    <div className="article-teaser__content">
      <ArticleTeaserContentTitle>
        <Link to={url}>{teaserCapitalized}</Link>
      </ArticleTeaserContentTitle>
      {articleIntroductionText ? (
        <div className="article-teaser__article-introduction-text">{articleIntroductionText}</div>
      ) : null}
      <TagsList tags={tags} />
      <Button
        ariaLabel={articleTeaserCtaButtonAriaLabel || articleTeaserCTAButtonText}
        btnColorVariant="outline-white"
        link={url}
      >
        {articleTeaserCTAButtonText}
      </Button>
    </div>
  );
};

export default ArticleTeaserContent;
