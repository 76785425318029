import React, { FC } from 'react';

import { IArticleTeaser } from 'gatsby-theme-husky/src/components/ArticleTeaser/models';
import 'gatsby-theme-husky/src/components/ArticleTeaser/ArticleTeaser.scss';
import ArticleTeaserContent from './ArticleTeaserContent';
import ArticleTeaserBanner from './ArticleTeaserBanner';

const ArticleTeaser: FC<IArticleTeaser> = ({ page, articleTeaser }) => {
  return (
    <div className="article-teaser">
      <ArticleTeaserContent {...{ articleTeaser, page }} />
      <ArticleTeaserBanner {...{ articleTeaser }} />
    </div>
  );
};

export default ArticleTeaser;
