import React, { FC, useMemo } from 'react';
import ArticleCard from 'components/ArticleCard';
import Pagination from 'components/Pagination';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { IArticleCardsList } from './models';

import './ArticleCardsList.scss';

const ArticleCardsList: FC<IArticleCardsList> = ({
  data,
  readMoreText,
  ariaLabel,
  nextAria,
  prevAria,
  handleActiveListPage,
  currentPage,
  isWithoutUrlParams,
  quantityItemsDesktop,
  quantityItemsMobile,
}) => {
  const { isMobile } = useScreenRecognition();
  const mobilePerPage = quantityItemsMobile || 3;
  const desktopPerPage = quantityItemsDesktop || 6;
  const perPage = isMobile ? mobilePerPage : desktopPerPage;
  const pages = Math.ceil(data.length / perPage);

  const articles = useMemo(() => {
    return data.slice(currentPage * perPage, currentPage * perPage + perPage);
  }, [data, currentPage, perPage]);

  return (
    <div className="articles-cards">
      <div className="articles-cards__wrapper">
        {articles?.map((article) => (
          <ArticleCard
            key={article.id}
            article={article}
            readMoreText={readMoreText}
            ariaLabel={ariaLabel}
            showTags
          />
        ))}
      </div>
      {data.length > 0 ? (
        <Pagination
          handleActiveListPage={handleActiveListPage}
          pageCount={pages}
          activePage={currentPage}
          prevContent={prevAria}
          nextContent={nextAria}
          isWithoutUrlParams={isWithoutUrlParams}
        />
      ) : null}
    </div>
  );
};

export default ArticleCardsList;
