import React from 'react';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';

const ArticleTeaserBanner = ({
  articleTeaser: {
    localImage,
    properties: { articleHeroBannerMainImageAltText },
  },
}) => {
  return (
    <div className="article-teaser__banner">
      {localImage ? (
        <GatsbyImage
          wrapperClasses="article-teaser__banner-frame"
          isLazyLoading={false}
          fluid={localImage?.childImageSharp.fluid}
          alt={articleHeroBannerMainImageAltText}
        />
      ) : null}
    </div>
  );
};

export default ArticleTeaserBanner;
