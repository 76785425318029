import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';
import HeroBanner from 'components/ArticleListingHeroBanner';
import { Container, Row, Layout } from 'layout';
import ArticleTeaser from 'components/ArticleTeaser';
import ArticleCardsList from 'components/ArticleCardsList';
import SecondaryNavigation from 'components/SecondaryNavigation';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { TArticleListingPageComponentProps } from './models';
import './ArticleListingPageMain.scss';
import './ArticleListingPageRTL.scss';

const ArticleListingPage: FC<TArticleListingPageComponentProps> = ({
  data: {
    page,
    articleTeaser,
    relatedArticles,
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
  },
}) => {
  const {
    defaultCompositions,
    defaultCompositions: {
      commonSettings: {
        blocks: [
          {
            properties: { nextAria, prevAria },
          },
        ],
      },
    },
    properties: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      seoCanonicalUrl,
    },
  } = page;
  const { isMobile, isTablet } = useScreenRecognition();
  const [currentPage, setCurrentPage] = useState<number>(0);
  let bannerImage = page.imageLg;

  if (isMobile && page.imageSm) {
    bannerImage = page.imageSm;
  } else if (isTablet && page.imageMd) {
    bannerImage = page.imageMd;
  }

  return (
    <Layout
      {...{
        defaultCompositions,
        disclaimerDesktopImage,
        disclaimerMobileImage,
        handleActiveListPage: setCurrentPage,
      }}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoExternalHreflangs,
        seoCanonicalUrl,
      }}
    >
      <Container fluid>
        <HeroBanner
          inFirstViewport
          image={bannerImage}
          altImageText={page.properties.heroBannerMainImageAltText}
          title={page.properties.heroBannerTitle}
          description={page.properties?.heroBannerDescription}
          url={page.url}
          cta={page.properties.heroBannerCTA}
          className="article-listing-hero-banner"
        />
        <Container className="article-listing-page__content">
          <SecondaryNavigation
            categoriesList={page.properties.categoriesList}
            currentCategory={page.properties.currentCategory}
            categoriesListShowButtonText={page.properties.categoriesListShowButtonText}
            categoriesListHideButtonText={page.properties.categoriesListHideButtonText}
          />
          {articleTeaser ? (
            <Row key="article-teaser" className="article-teaser-wrapper">
              <ArticleTeaser page={page} articleTeaser={articleTeaser} />
            </Row>
          ) : null}
          <div className="article-cards-wrapper">
            <ArticleCardsList
              handleActiveListPage={setCurrentPage}
              currentPage={currentPage}
              data={relatedArticles.nodes}
              readMoreText={page.properties.articleCardCTAText || 'Read more'}
              ariaLabel={page.properties.articleCardCTAAriaLabel || 'Read more'}
              paginationText={page.properties?.paginationText}
              nextAria={nextAria}
              prevAria={prevAria}
              isWithoutUrlParams={false}
            />
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export default ArticleListingPage;

export const query = graphql`
  query ArticleListingPage(
    $url: String = ""
    $articleTeaserLink: String = ""
    $links: [String] = ""
    $lang: String
  ) {
    page: umbracoArticlesListing(url: { eq: $url }, lang: { eq: $lang }) {
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      properties {
        paginationText {
          properties {
            nextPage
            prevPage
          }
        }
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        seoCanonicalUrl
        title
        heroBannerMainImageAltText
        heroBannerTitle
        heroBannerDescription
        heroBannerCTA {
          url
          name
        }
        articleTeaser {
          url
        }
        articleTeaserCTAButtonText
        articleTeaserCtaButtonAriaLabel
        categoriesListShowButtonText
        categoriesListHideButtonText
        categoriesList {
          structure
          properties {
            categoryName
            categoryOrder
            isCategoryShow
            categoryUrl {
              url {
                url
                name
              }
              name
            }
          }
        }
        tags {
          name
          url {
            name
            url
          }
        }
        currentCategory {
          name
          url {
            url
          }
        }
        articleCardCTAText
        articleCardCTAAriaLabel
      }
      imageLg: bannerImage {
        childImageSharp {
          fluid(maxWidth: 500, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSm: bannerImage {
        childImageSharp {
          fluid(maxWidth: 200, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMd: bannerImage {
        childImageSharp {
          fluid(maxWidth: 400, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      url
    }
    articleTeaser: umbracoArticles(url: { eq: $articleTeaserLink }) {
      url
      properties {
        articleHeroBannerMainImageAltText
        articleBody {
          structure
          properties {
            articleIntroductionText
          }
        }
        title
        tags {
          name
          url {
            url
            name
          }
        }
        articleHeroBannerMainImageAltText
      }
      localImage {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $links } }) {
      nodes {
        id
        title
        properties {
          title
          tags {
            name
            url {
              url
              name
            }
          }
          articleBody {
            structure
            properties {
              articleIntroductionText
            }
          }
          articleHeroBannerMainImageAltText
        }
        url
        localImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;
